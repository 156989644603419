<template>
    <div>
        <div class="top">
            <div class="title">退款 / 售后</div>
        </div>
        <div class="list">
            <div>
                <table border="0" cellspacing="0" cellpadding="0">
                    <tr class="list-title">
                        <th width="450">商品信息</th>
                        <th width="210">单价 (元)</th>
                        <th width="210">数量</th>
                        <th width="210">优惠</th>
                        <th width="210">实付款(元)</th>
                    </tr>
                </table>

                <!--订单号-->
                <table
                    style="border-collapse:separate; border-spacing:0 10px;"
                    border="0"
                    cellspacing="0"
                    cellpadding="0"
                >
                    <tr class="order-mian" style="margin:10px">
                        <td width="450">
                            <span class="order-number">
                                {{ query.CreatTime }}</span
                            >
                            订单号 {{ query.OrderID }}
                        </td>
                        <td width="210" v-for="j in 4" :key="j"></td>
                    </tr>
                    <tr class="order-mian-list">
                        <td style="display: flex" class="border">
                            <div>
                                <img :src="pic + query.ProPictrue" alt="" />
                            </div>
                            <div class="order-mian-list-text">
                                <div>{{ query.ProName }}</div>
                            </div>
                        </td>
                        <td class="border">
                            <div>￥{{ query.ProUnitPrice }}</div>
                        </td>
                        <td class="border">
                            <div>X {{ query.PCount }}</div>
                        </td>
                        <td class="border">
                            <div>- {{ query.DisPrice }}</div>
                        </td>
                        <td class="border">
                            <div class="subtotal">
                                <div>￥ {{ query.LastPrice }}</div>
                                <div class="subtotal-tax">
                                    (含税费￥{{ query.TaxPrice }})
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="logistics">
            <div class="logistics-top">
                退货地址
            </div>
            <table border="1" cellspacing="0" cellpadding="0">
                <tr class="logistics-text">
                    <td width="1050">
                        <div class="addr">
                            <div class="addr-top">
                                <div>收货人：{{ order.ExUserNamePhone }}</div>
                                <div>收货地址：{{ order.ExAddress }}</div>
                            </div>
                            <div class="addr-bottom">
                                <div>
                                    <span>需将纸质发票与商品一同寄回，</span>
                                    未与商家协商一致，请勿使用到付或平邮，以免商家拒签货物
                                </div>
                                <div>
                                    请完整填写真实退货物流信息，逾期未填写，退货申请将关闭，关闭后若超出保障期，将无法再次发起售后申请
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        <div class="logistics">
            <div class="title">物流信息</div>
            <div class="form">
                <el-form
                    :model="ruleForm"
                    :rules="rules"
                    ref="ruleForm"
                    label-width="100px"
                    class="demo-ruleForm"
                >
                    <el-form-item label="物流单号" prop="TrackNumber">
                        <el-input
                            style="width: 150px"
                            size="mini"
                            v-model="ruleForm.TrackNumber"
                            placeholder="请输入订单号"
                        >
                        </el-input>
                    </el-form-item>
                    <el-form-item label="物流公司" prop="TrackComName">
                        <el-input
                            style="width: 150px"
                            size="mini"
                            v-model="ruleForm.TrackComName"
                            placeholder="请输入物流公司"
                        >
                        </el-input>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="botton" @click="submitForm('ruleForm')">
            <el-button>确认提交</el-button>
        </div>
    </div>
</template>

<script>
import { sendTrack, getExOrderDetail } from './service'

export default {
    data() {
        return {
            type: 0,
            dialogVisible: false,
            url: '',
            order: [],
            ruleForm: {},
            rules: {
                TrackNumber: [
                    { required: true, message: '请选择内容', trigger: 'change' }
                ],
                TrackComName: [
                    { required: true, message: '请选择内容', trigger: 'change' }
                ]
            },
            pic: FILE_URL,
            query: {}
        }
    },
    methods: {
        handleSuccess(response, file, fileList) {
            this.fileList = fileList
        },
        handleRemove(file, fileList) {
            this.fileList = fileList
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url
            this.dialogVisible = true
        },
        submitForm(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    this.ruleForm.ExOrderID = this.$route.query.OrderID
                    sendTrack(this.ruleForm).then(() => {
                        this.$message.success('提交成功')
                        this.$router.push({
                            path: '/me/sales'
                        })
                    })
                } else {
                    return false
                }
            })
        }
    },
    mounted() {
        this.query = this.$route.query
        this.type = this.$route.query.type
        this.query.PCount = parseInt(this.query.PCount)
        this.url = FILE_URL + '/UploadFile/UploadImg'
        getExOrderDetail({
            ExOrderID: this.$route.query.OrderID
        }).then(res => {
            this.order = res.data
        })
    }
}
</script>

<style lang="less" scoped>
.botton {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    button {
        border: none;
        color: #ffffff;
        background: #dc2310;
        border: none;
    }
}

.logistics {
    border: 1px solid #f4f4f4;
    .title {
        background: #f9f9f9;
        padding: 5px;
        font-family: 'ct';
    }
    .form {
        margin-top: 30px;
    }
}

.lastPrice {
    font-family: 'ct';
    color: #dc2310;
    font-size: 17px;
}

.subtotal {
    font-family: 'ct';
    color: #1b98aa;
    font-size: 17px;

    .subtotal-tax {
        font-family: '微软雅黑';
        font-size: 12px;
        color: #999999;
    }
}

.top {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 5px;

    .title {
        font-size: 18px;
        font-family: 'ct';
    }

    .policy {
        font-size: 12px;
        color: #0097ba;
        margin-top: 10px;
    }
}

.list {
    margin-top: 10px;

    .list-title {
        background: #eeeeee;
        height: 40px;
        text-align: center;
    }

    .order-mian {
        width: 1300px;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        margin: 10px 0;
        background: #f9f9f9;
        padding-left: 10px;
        box-sizing: border-box;
    }

    .order-mian-list {
        text-align: center;
        font-size: 12px;
        margin: 10px 0;
    }

    .order-mian-list-text {
        text-align: left;
        margin: 10px;
    }

    .subtotal {
        font-family: 'ct';
        color: #1b98aa;
        font-size: 17px;
    }

    .button {
        width: fit-content;
        padding: 2px 10px;
        margin: 10px auto;
        background: rgba(27, 152, 170, 0);
        border: 1px solid #d9d9d9;
        color: #999999;
    }

    img {
        width: 100px;
        height: 100px;
    }

    .border {
        padding-bottom: 10px;
        border-bottom: 1px solid #f4f4f4;
    }

    .border-left {
        border-left: 1px solid #f4f4f4;
    }

    .border-right {
        border-right: 1px solid #f4f4f4;
        border-bottom: 1px solid #f4f4f4;
    }

    .order-details {
        margin-top: 30px;
    }

    .paidui {
        color: #999999;

        span {
            margin-left: 5px;
        }
    }

    .handle {
        width: fit-content;
        margin: 10px auto;
        padding: 5px 15px;
        color: #ffffff;
        background: #1b98aa;
    }

    .view {
        width: fit-content;
        margin: 10px auto;
        padding: 5px 15px;
        color: #333333;
        background: rgba(238, 238, 238, 0);
        border: 1px solid #dcdcdc;
    }

    .red {
        color: #dc2310;
    }
}
.logistics {
    text-align: left;
    margin: 10px 0;

    .logistics-top {
        line-height: 40px;
        height: 40px;
        background: #f9f9f9;
        padding-left: 20px;
        box-sizing: border-box;
        font-family: 'ct';
    }

    table {
        border: 1px solid #f4f4f4;
        border-collapse: collapse;
        border-spacing: 0;
    }

    .logistics-text {
        font-family: '微软雅黑';
        font-size: 13px;
        color: #666666;

        span {
            color: #1b98aa;
            cursor: pointer;
            margin-left: 10px;
        }

        td {
            height: 40px;
            line-height: 40px;
            padding-left: 20px;
            border-top: none;
        }
    }
}
</style>
